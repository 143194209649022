/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { API_URL, APIV } from './../config/constant';
import secureStorage from './../config/encrypt';
import { toast } from 'react-toastify';
import ls from "local-storage";
import { DecryptData, encryptData } from '../config/req_encrypt';

const Axios = axios.create();
Axios.interceptors.response.use(
    (response) => {
        if (response.data && response?.status !== 200) {
            console.error(`${response?.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        ls.set("is_redirect", 1)
        return response;
    },
    (error) => {
        // handle error
        if (error.response) {
            console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
            if (error.response.status == 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (error.response.status == 401) {
                // if(error.response.data.errors == "TokenEmptyInCache") 
                //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
                let is_redirect = ls.get("is_redirect")
                if(is_redirect !== 0){
                    window.location.href = '/logout';
                }
                ls.set("is_redirect", 0)
            }
        }

        return error.response;
    });

const errorHandler = error => {
    return Promise.reject(error);
};


window.commonErrorToast = function (error) {
    if (typeof error == "string") toast.error(error);
    else if (error.message) toast.error("Helllo", error.message);
}
export default {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
           // console.log('$$$$$$$$$$$$$$',fetchHeader.token);
        return (fetchHeader && fetchHeader.token)
            ? fetchHeader.token : null;
    },
    delete(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.delete(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    async post(URL, body, headers) {
        try {
            headers = headers || {};
            if (APIV) headers['apiv'] = APIV;
            headers['Authorization'] = this.getToken();
            headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
            URL = (URL[0] === '/') ? URL.substr(1) : URL;
            if (body && URL != "sfa/user/login-by-email") {
                let encrypt_data = await encryptData(body, URL)
                body.requestData = encrypt_data.encryptedReqData
                headers["encryptedsymmetrickey"] = encrypt_data.symmetricKey
                headers["encryptediv"] = encrypt_data.iv
            }
            let response = await Axios.post(`${API_URL.GETWAY_API}${URL}`, body, { headers, withCredentials: false })
            if (response?.data?.encryptedData) {
                let decrypt_response = await DecryptData(response.data)
                response.data = decrypt_response
            }
            return response
        } catch (error) {
        }
    },
    put(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.put(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    async get(URL, query, headers) {
        try {
            headers = headers || {};
            if (APIV) headers['apiv'] = APIV;
            headers['Authorization'] = this.getToken();
            headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
            //headers['apikey'] = API_URL.API_KEY;
            URL = (URL[0] === '/') ? URL.substr(1) : URL;
            let response = await Axios.get(`${API_URL.GETWAY_API}${URL}`,
                { params: query, headers })
            if (response?.data?.encryptedData) {
                let decrypt_response = await DecryptData(response.data)
                response.data = decrypt_response
            }
            return response
        } catch (error) {
        }
    },
    uploadFilesToS3(postdata, headers) {
        headers = headers || {};
        return axios.post(
            `${API_URL.COMMON_GATEWAY_API}core/commonservice/docs_upload`,
            postdata,
            { headers }
        );
    },
    getLocalityList(params, headers) {
        headers = headers || {};
        return axios.get(
            `${API_URL.COMMON_GATEWAY_API}core/commonservice/locality_list?city=`+params,
            { headers }
        );
    },   
    download(URL, body, responseType,  headers) {
        headers = headers || {};       
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;        
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.post(`${API_URL.GETWAY_API}${URL}`,  body, {responseType, headers,withCredentials:false })
    },
    async postCommon(URL, body, headers) {
        headers = headers || {};       
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;        
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        if (body) {
            let encrypt_data = await encryptData(body, URL)
            body.requestData = encrypt_data.encryptedReqData
            headers["encryptedsymmetrickey"] = encrypt_data.symmetricKey
            headers["encryptediv"] = encrypt_data.iv
        }
        let response = await Axios.post(`${API_URL.SFA_COMMON_API}sfa-common/${URL}`, body, { headers,withCredentials:false })
        // let decrypt_response = await DecryptData(response.data)
        // response.data = decrypt_response
        return response
    },
    async getPublicKeySFA() {
        let res = await axios.get(`${API_URL.GETWAY_API}/getPublicKey`,
            { headers: {} }
        );
        if (res?.data?.data) {
            secureStorage.setItem("public_key", res.data.data)
        }
        return ""
    },
}